.profile-main-loader {
  width: 100%;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 100px;
  height: 100px;
}
.profile-main-loader .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 24, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 24, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #fe7ab9;
  }
  25%,
  33% {
    stroke: #fee766;
  }
  58%,
  66% {
    stroke: #7ad0fe;
  }
  92%,
  100% {
    stroke: #fe7ab9;
  }
}
@keyframes color {
  0% {
    stroke: #fe7ab9;
  }
  25%,
  33% {
    stroke: #fee766;
  }
  58%,
  66% {
    stroke: #7ad0fe;
  }
  92%,
  100% {
    stroke: #fe7ab9;
  }
}
